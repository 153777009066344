.loader {
    width: 100px;
    height: 100px;
    position: relative;
    margin: auto;
    top: 29%;
}

.loader div {
    position: absolute;
    width: 35%;
    height: 35%;
    border-radius: 5px;
    animation: load 2s infinite ease-in-out;
}

.loader div:nth-of-type(1) {
    background-color: #1e4395;
}

.loader div:nth-of-type(2) {
    background-color: #231f20;
    animation-delay: 0.5s;
}

.loader div:nth-of-type(3) {
    background-color: #1e4395;
    animation-delay: 1s;
}

.loader div:nth-of-type(4) {
    background-color: #231f20;
    animation-delay: 1.5s;
}

@keyframes load {
    0% {
        transform: translate(0%);
        border-radius: 50%;
    }
    25% {
        transform: translate(200%) rotate(45deg);
        border-radius: 0%;
    }
    50% {
        transform: translate(200%, 200%);
        border-radius: 50%;
    }
    75% {
        transform: translate(0, 200%) rotate(-45deg);
        border-radius: 0%;
    }
    100% {
        transform: translate(0%);
        border-radius: 50%;
    }
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f5ffe8;
    z-index: 9999999;
}


/* common */

.loader {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
    animation: animate 2s linear infinite;
}

.loader::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
    background: #000;
    border-radius: 50%;
    z-index: 1000;
}

.loader::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
    border-radius: 50%;
    filter: blur(60px);
    z-index: 1;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
        filter: hue-rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        filter: hue-rotate(360deg);
    }
}

.loading-text {
    font-size: 20px;
    position: relative;
    top: 30%;
    text-align: center;
    color: #fff !important;
}

.loader-container {
    position: fixed;
    z-index: 999;
    /* height: 2em;
    width: 2em; */
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader-container:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgb(20 20 20 / 73%), rgba(0, 0, 0, .8));
    background: -webkit-radial-gradient(rgb(20 20 20 / 73%), rgba(0, 0, 0, .8));
}