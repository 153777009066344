    .filter-home {
        float: right;
        margin-bottom: 15px;
    }
    
    .overflow {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .border {
        border: 1px dashed #0095e8 !important;
    }
    
    .text-yellow {
        color: #FB9328;
    }
    
    .float-right {
        float: right;
    }
    
    .swal2-popup {
        background: #181426;
    }
    
    .swal2-popup .swal2-content,
    .swal2-popup .swal2-html-container {
        font-size: 1.5rem !important;
        text-transform: capitalize;
        color: #fff;
    }
    
    .swal2-styled.swal2-confirm {
        background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box !important;
        background-color: unset;
    }
    
    @media only screen and (min-width: 768px) {}
    
    @media only screen and (max-width: 768px) {
        /* .desk-top-view {
        display: none;
    }
    .mobile-view {
        display: block;
        font-size: 15px !important;
        text-align: center;
        margin-top: 150px;
    }
    .header {
        display: none;
    } */
        .is-sticky {
            padding-bottom: 0px !important;
            top: 70px !important;
        }
        .home-tab {
            font-size: 13px !important;
        }
        .slick-next {
            right: 56px !important;
        }
        .slick-prev {
            left: 15px !important;
        }
    }
    
    .home-tab {
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        border-radius: 25px;
        border: 1px solid;
        cursor: pointer;
    }
    
    .home-tab.active {
        background: #fec22a;
        color: #2e211f;
    }
    
    .text-blue {
        color: #1e4395;
    }
    
    .lazy-loader .loader {
        position: relative;
        width: 80px;
        height: 80px;
        border: 4px solid #240229;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: -5px -5px 5px rgba(255, 255, 255, .1), 10px 10px 10px rgba(0, 0, 0, .4), inset -5px -5px 5px rgba(255, 255, 255, .2), inset 10px 10px 10px rgba(0, 0, 0, .4);
    }
    
    .lazy-loader .loader .text {
        /* content: "Loading"; */
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 25px;
        left: 25px;
        right: 25px;
        bottom: 25px;
        z-index: 2;
        background: #240229;
        border-radius: 50%;
        border: 2px solid #240229;
        box-shadow: inset -2px -2px 5px rgba(255, 255, 255, .2), inset 3px 3px 5px rgba(0, 0, 0, .5);
    }
    
    .lazy-loader .loader .text h6 {
        font-weight: 300;
        font-size: 0.9em;
        letter-spacing: 1px;
        animation: fade 1.5s ease-in-out infinite;
    }
    
    .lazy-loader .loader span {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
        animation: rotate .5s linear infinite;
    }
    
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    @keyframes fade {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    
    .spinner-container-v1 {
        /* width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
    background-color: #ffffff; */
        position: relative;
        text-align: center;
    }
    
    .spinner-v1 {
        margin: auto;
        border: 4px solid #dbf2ff;
        /*
  change to dotted for something cool.
  change width to 1px for tapered bar
  */
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: 45%;
        border-radius: 50%;
        border-right: 4px solid #FFDC2B;
        text-align: center;
        animation-name: spin;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        /*animation-timing-function: linear; /*linear spin */
        animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
    }
    
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        /*50% {width:24px;height:24px} /*shrinking effect*/
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        /*50% {width:24px;height:24px} /*shrinking effect*/
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    
    .is-sticky {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
        /* background-color: #0a0911 !important; */
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding-top: 0px;
        padding-bottom: 10px;
        backdrop-filter: blur(40px);
        /* padding-left: 132px; */
        padding-left: 9%;
    }
    
    .back-to-top-button {
        display: inline-block;
        background-color: #ffc107;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 30px;
        position: fixed;
        bottom: 115px;
        right: 30px;
        transition: background-color .3s, opacity .5s, visibility .5s;
        /* opacity: 0; */
        /* visibility: hidden; */
        z-index: 1000;
    }
    
    .back-to-top-button::after {
        content: "\F13A";
        font-family: 'bootstrap-icons';
        font-weight: normal;
        font-style: normal;
        font-size: 2em;
        line-height: 50px;
        color: #2e211f;
    }
    
    .back-to-top-button:hover {
        cursor: pointer;
        background-color: #333;
    }
    
    .back-to-top-button:hover::after {
        color: #fff;
    }
    
    .back-to-top-button:active {
        background-color: #555;
    }
    /** table css**/
    
    .main-table .rdt_Table {
        border-radius: 20px;
        /* border: 1px solid; */
        background-color: rgb(148 163 184 / 0.3);
    }
    
    .rdt_TableHeadRow {
        background-color: #404144 !important;
        color: #fff;
        border-radius: 20px 20px 0px 0px;
    }
    
    .rdt_TableRow {
        background-color: transparent !important;
        color: #fff !important;
        font-weight: 600 !important;
        height: 65px;
    }
    
    .rdt_TableCol {
        font-size: 15px;
    }
    
    .rdt_TableCell {
        font-size: 15px;
    }
    
    .predict-now-btn {
        background: #FFDC2B;
        padding: 8px 16px;
        border-radius: 52px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
    
    .rwd-table {
        margin: auto;
        min-width: 100%;
        max-width: 100%;
        border-collapse: collapse;
    }
    
    .rwd-table tr:first-child {
        border-top: none;
        background: unset;
        color: #fff;
    }
    
    .rwd-table tr {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background-color: #f5f9fc;
    }
    
    .rwd-table th {
        display: none;
    }
    
    .rwd-table td {
        display: block;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }
    
    .rwd-table td:first-child {
        margin-top: .5em;
    }
    
    .rwd-table td:last-child {
        margin-bottom: .5em;
    }
    
    .rwd-table td:before {
        content: attr(data-th) ": ";
        font-weight: bold;
        width: 120px;
        display: inline-block;
        color: #FFDC2B;
    }
    
    .rwd-table th,
    .rwd-table td {
        text-align: left;
    }
    
    .rwd-table {
        color: #333;
        border-radius: 22px;
        overflow: hidden;
    }
    
    .rwd-table tr {
        border-color: #bfbfbf;
    }
    
    .rwd-table th,
    .rwd-table td {
        padding: .5em 1em;
    }
    
    @media screen and (max-width: 601px) {
        .rwd-table tr:nth-child(2) {
            border-top: none;
        }
    }
    
    @media screen and (min-width: 600px) {
        .rwd-table tr:hover:not(:first-child) {
            background-color: #d8e7f3;
        }
        .rwd-table td:before {
            display: none;
        }
        .rwd-table th,
        .rwd-table td {
            display: table-cell;
            padding: .25em .5em;
        }
        .rwd-table th:first-child,
        .rwd-table td:first-child {
            padding-left: 0;
        }
        .rwd-table th:last-child,
        .rwd-table td:last-child {
            padding-right: 0;
        }
        .rwd-table th,
        .rwd-table td {
            padding: 1em !important;
        }
    }
    /* THE END OF THE IMPORTANT STUFF */
    
    @-webkit-keyframes leftRight {
        0% {
            -webkit-transform: translateX(0)
        }
        25% {
            -webkit-transform: translateX(-10px)
        }
        75% {
            -webkit-transform: translateX(10px)
        }
        100% {
            -webkit-transform: translateX(0)
        }
    }
    
    @keyframes leftRight {
        0% {
            transform: translateX(0)
        }
        25% {
            transform: translateX(-10px)
        }
        75% {
            transform: translateX(10px)
        }
        100% {
            transform: translateX(0)
        }
    }
    
    .poll-time {
        color: #fff;
        font-size: 14px !important;
        font-weight: 700 !important;
    }
    
    .rwd-table tr:first-child td:first-child {
        border-top-left-radius: 10px;
    }
    
    tr:first-child td:last-child {
        border-top-right-radius: 10px;
    }
    
    tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
    }
    
    tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }
    
    tr:first-child td {
        border-top-style: solid;
    }
    
    tr td:first-child {
        border-left-style: solid;
    }
    
    .rwd-table th {
        font-weight: 700;
        line-height: 17px;
        font-size: 14px;
        color: #fff;
    }
    
    .rwd-table tr:first-child {
        border: unset;
    }
    
    .rwd-table tr:first-child th {
        text-align: left;
    }
    
    .rwd-table tr:nth-child(even):not(:first-child) {
        background: unset;
        background: #262110;
        color: #fff;
        border: unset;
        border-color: unset;
    }
    
    .rwd-table tr:nth-child(odd):not(:first-child) {
        background: #6f5c23;
        color: #fff;
        border: unset;
        border-color: unset;
    }
    
    .rwd-table tr:nth-child(2) td:first-child {
        border-top-left-radius: 25px;
    }
    
    .rwd-table tr:nth-child(2) td:last-child {
        border-top-right-radius: 25px;
    }
    
    .rwd-table tr:nth-child(2) td:first-child {
        border-top-left-radius: 22px;
    }
    
    .rwd-table tr:nth-child(2) td:last-child {
        border-top-right-radius: 22px;
    }
    
    .poll-image {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50px;
    }
    
    @media screen and (max-width: 470px) {
        /* CSS rules go here */
        .rwd-table tr:not(:first-child) {
            display: block;
            padding: 1rem 0.5rem 1rem 0.5rem;
            margin: 1.5rem auto;
            border: 1px solid grey;
            border-radius: 10px;
            box-shadow: 0 0 10px #262110;
        }
        .rwd-table tr:nth-child(odd):not(:first-child) {
            background: #262110;
        }
    }