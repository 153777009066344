.search-container-raffle-detail {
    background-size: auto 100%;
    background: unset;
    width: 100%;
    padding-top: 50px;
    /* z-index: 9; */
}

.poll-detail-page {
    max-width: fit-content;
}

.detail-page-collection-name {
    letter-spacing: 0px;
    /* color: #ffc107; */
    color: #302A47;
    font-size: 15px;
    font-weight: 600;
}

.header-tabs .nav .nav-link.active {
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.tab-pane .card {
    border-radius: 0px 20px 20px 20px;
}

.pointer {
    cursor: pointer;
}


/* .raffle-detail-card {
    min-height: 300px;
    max-height: 300px;
} */

.back-arrow i {
    font-size: 20px;
    color: #fff;
}

.poll-name {
    font-size: 32px;
    color: #fff;
    font-weight: 500;
    line-height: 38px;
    font-weight: 700;
    /* line-height: 2; */
}

.poll-left-col {
    width: 70%;
    padding: 20px;
}

.poll-right-col {
    width: 30%;
    background-color: #7d704d;
    height: 100vh;
    overflow: hidden;
}

.poll-row {
    display: flex;
}

.vote-card-left {
    width: 49%;
}

.vote-card-right {
    width: 49%;
}

.vote-card {
    display: flex;
    background: #f4cd6b;
    padding: 10px;
    border-radius: 20px;
}

.heading-3 {
    font-size: 19px;
    color: #fff;
    font-weight: 500;
}

.back-to {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.poll_collection_image {
    width: 160px;
    height: 160px;
    border-radius: 9.3px;
}

.poll-detail-container {
    padding-left: 10px;
}

.poll-head-details {
    padding: 0.5rem;
    /* border: 1px solid #ffecb3; */
    background: rgba(255, 220, 43, 0.05);
    border-radius: 8.66928px;
    text-align: center;
    min-height: 95px;
}

.poll-head-details-label {
    color: #e4e6ef;
    font-size: 15px;
    font-weight: 500;
}

.poll-head-details-text {
    color: #FFDC2B;
    font-size: 20px;
    font-weight: 700;
}

.predict-label {
    font-size: 1.6rem;
    color: #FFDC2B;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.predict-up-btn-container {
    border: 1px solid;
    border-radius: 25px;
    cursor: pointer;
    /* width: inherit; */
    padding: 10px;
    background: #4D8522;
    font-size: 16px;
    text-align: center;
    margin: auto;
    font-weight: 600;
    color: #fff;
    border-color: #FFDC2B;
}

.predict-up-btn-container.active,
.predict-down-btn-container.active {
    border: 4px solid #FFDC2B;
}

.predict-down-btn-container {
    border: 1px solid;
    border-radius: 25px;
    cursor: pointer;
    /* width: inherit; */
    padding: 10px;
    background: #8C1919;
    font-size: 16px;
    text-align: center;
    margin: auto;
    font-weight: 600;
    color: #fff;
    border-color: #FFDC2B;
}

.predict-container {
    width: 50%;
    margin: auto;
    /* border: 1px solid #7e8299; */
    border-radius: 10px;
    padding: 16px;
    background: #9d8d5b;
    box-shadow: 4px 6px 23px 0px rgb(0 0 0 / 15%), 1px 2px 4px 0px rgb(0 0 0 / 25%);
    /* margin-top: 30px; */
}

.participant-container {
    margin-top: 40px;
    width: 85%;
    margin: auto;
    margin-bottom: 50px;
}

hr.new4 {
    border: 1px solid #333;
}

.predict-up-btn-container .up-icon {
    font-size: 13px;
    color: #fff;
    background: #7ec74d;
    font-weight: 700;
    border-radius: 50px;
    padding: 5px;
}

.predict-down-btn-container .down-icon {
    font-size: 13px;
    color: #fff;
    background: #d34040;
    font-weight: 700;
    border-radius: 50px;
    padding: 5px;
}

.poll-floor-now-label {
    color: #fec22a;
    font-size: 17px;
}

.poll-floor-now-text {
    color: #e4e6ef;
    font-size: 20px;
    font-weight: 600;
}

.poll-form-label {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.poll-time-detail {
    color: #FFDC2B;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.question-container {
    width: 85%;
    margin: auto;
    padding: 45px;
    background: #413717;
    border-radius: 22px;
    border: 1px solid #FFCD45;
}

.predict-now-btn-detail {
    background: #FFDC2B;
    border-radius: 52px;
    font-weight: 700;
    padding: 10px 30px 10px 30px;
    width: 100%;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    height: 50px;
}

.connect-wallet-p-d-page {
    background-color: #FFDC2B !important;
    color: #000000 !important;
    line-height: unset !important;
}

.Toastify__toast {
    font-family: inherit !important;
    font-size: 16px !important;
    font-weight: 600;
    color: #000 !important;
}

.Toastify__spinner {
    border-right-color: #1e4395 !important;
}

.Toastify__toast-container {
    top: 10em !important;
}

.loading {
    position: fixed;
    left: 0px;
    top: 40%;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
}

.loading-dots {
    /* position: fixed;
  left: 0px;
  top: 40%;
  width: 100%;
  height: 100%;
  z-index: 9999; */
    text-align: center;
    font-size: 2em;
    color: rgba(255, 2555, 255, 0);
    animation-name: loading-dots-animation;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes loading-dots-animation {
    0% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .2);
    }
    20% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, 1), 0em 0 0 rgba(255, 2555, 255, .6), 1em 0 0 rgba(255, 2555, 255, .2);
    }
    40% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, 1), 1em 0 0 rgba(255, 2555, 255, 0.6);
    }
    60% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .2), 0em 0 0 rgba(255, 2555, 255, .6), 1em 0 0 rgba(255, 2555, 255, 1);
    }
    80% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .2), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .6);
    }
    100% {
        text-shadow: -1em 0 0 rgba(255, 2555, 255, .6), 0em 0 0 rgba(255, 2555, 255, .2), 1em 0 0 rgba(255, 2555, 255, .2);
    }
}

.loading-text-1 {
    font-weight: bold;
    color: #fff;
    margin: 0.3em 0;
    font-size: 1.5em;
}

.form-group-help {
    font-size: 0.875rem;
    margin-top: 8px;
    display: block;
    color: #fff;
}

details summary {
    /* width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0; */
    display: block;
    outline: none;
    color: #fff;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

details summary:hover {
    cursor: pointer;
    opacity: 1;
}

details summary svg {
    width: 20px;
    height: 20px;
}

details summary::-webkit-details-marker {
    display: none;
}

.forze-text {
    color: #e4e6ef;
    font-size: 15px;
    font-weight: 500;
}

@media screen and (max-width: 470px) {
    .question-container {
        width: 100%;
        padding: 20px;
    }
    .participant-container {
        width: 100%;
    }
    .wallet-id {
        width: 20em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .poll_collection_image {
        width: 50px;
        height: 50px;
        border-radius: 9.3px;
    }
    .poll-name {
        font-size: 20px;
    }
    .no-participants-poll::before {
        content: unset !important;
    }
}