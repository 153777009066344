.card {
    border: unset;
}

.my-account .card-body {
    background: #fff !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.my-account {
    margin-bottom: 100px !important;
}

.text-blue {
    color: #0095e8 !important;
}

.border {
    border: 1px dashed #929eab !important;
}

.table.table-row-dashed tr {
    border-bottom-color: #929eab;
}

.my-account-img {
    width: 150px;
    height: 150px;
}

.my-account-small-box {
    /* background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box; */
    border: 1px solid #053C9B;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box-2 {
    /* background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box; */
    border: 1px solid #053C9B;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box .label,
.my-account-small-box-2 .label {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #053C9B;
}

.my-account-small-box .text,
.my-account-small-box-2 .text {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #000;
}

.slider-container-myaccount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -60px;
}

.switch-myaccount {
    position: relative;
    width: 430px;
    height: 60px;
    display: block;
    margin: auto;
    margin-bottom: auto;
    border: 5px solid #0C0A13;
    border-radius: 30px;
}

.switch-myaccount .toggle_vat {
    visibility: hidden;
}

.my-account-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #181426 !important;
}

.my-account-table-card .card-body table thead {
    border-bottom: 4px solid #0C0A13;
}

.my-account-table-card .card-body table thead th {
    font-size: 15px;
    color: #776D96;
}

.my-account-table-card .card-body table td {
    font-size: 17px;
}

.my-account-table-card td:not(:last-child),
.my-account-table-card th:not(:last-child) {
    border-right: 4px solid #0C0A13;
}

.btn-view-myaccount {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #fff;
}

.text-gray {}

.twitter-user-name-label {
    color: #776D96;
    font-size: 16px;
    font-weight: 600;
    /* border-bottom: 1px solid; */
}

.twitter-user-name a {
    color: rgba(96, 165, 250, 1);
    font-size: 16px;
    font-weight: 600;
    display: flex;
}

.twitter-user-name {
    color: rgba(96, 165, 250, 1);
    display: flex;
    font-weight: 600;
}

.text-blue-400 {
    --tw-text-opacity: 1!important;
    color: rgba(96, 165, 250, var(--tw-text-opacity))!important;
    width: 22px;
    padding-left: 5px;
}

.text-white-40 {
    --tw-text-opacity: 1!important;
    color: #fff;
    width: 22px;
    padding-right: 5px;
}

.fill-current {
    fill: currentColor!important;
}

.twiiter-connect-btn {
    background-color: rgba(96, 165, 250, 1) !important;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.twiiter-connect-btn:hover {
    background-color: rgba(96, 165, 250, 1) !important;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .slider-container-myaccount {
        top: -30px;
    }
    .my-account-card .raffle-img {
        width: 100%;
        height: unset;
        border-radius: 20px 20px 20px 20px
    }
    .my-account-card .view-col {
        background: unset !important;
    }
    .view-btn {
        width: inherit;
    }
    .raffle-info .raffle-ticket-type {
        min-width: unset;
    }
}

.my-account-card {
    background: #fff;
    border-radius: 20px;
    margin-top: 15px;
    padding: 5px;
    border-top: 4px solid #2898d5;
}

.my-account-card .raffle-img {
    width: 100%;
    /* height: 100%; */
    border-radius: 20px 20px 20px 20px;
}

.my-account-card .raffle-info {
    /* padding: 10px; */
}

.my-account-card .view-col {
    background: #f8f5ff;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-account-card .collec-name {
    color: #333;
    font-size: 15px;
    font-weight: 600;
}

.view-btn {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 30px;
    /* border: 1px solid #3f4254; */
    padding: 10px 15px;
    cursor: pointer;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
}

.raffle-winner-my {
    font-size: 19px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3a996b;
}

.sold-out {
    color: #3a996b;
}

.ticket-not-sold,
.ticket-not-sold .ticket-sold,
.ticket-not-sold .ticket-remain {
    color: #f44336;
}

.winning-change {
    margin-left: 10px;
    font-size: 13px;
}

.winner-card {
    border-top: 4px solid yellow;
}

.winner-card .fa-crown {
    font-size: 1.4rem;
    margin-right: 5px;
    color: #000;
    font-style: initial;
    font-family: 'Font Awesome 5 Free';
}

.winner-card .view-btn {
    background: #ffc107;
    color: #000;
}